import merge from '~lodash.mergewith'
import { init, Replay, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F6c0fff23bbe0fab97e3335e584454d6a@o282504.ingest.us.sentry.io\u002F4506232988893184",
    environment:"production",
    trackComponents:true,
    replaysSessionSampleRate:0,
    replaysOnErrorSampleRate:1,
    tracesSampleRate:1,
    release:"a58fe2a8ffa0e5042696628201547fa9b927a5f6",
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
    new Replay({ maskAllText:false,blockAllMedia:false }),
  ]

  const { browserTracing, vueOptions, vueRouterInstrumentationOptions, ...tracingOptions } = {"tracesSampleRate":1,"browserTracing":{},"vueOptions":{"trackComponents":true},"vueRouterInstrumentationOptions":{"routeLabel":"name"}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router, vueRouterInstrumentationOptions) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
